<template>
  <a-card class="card" :bordered="false">
    <!-- 搜索框 -->
    <a-form-model
      :model="queryParam"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="货物种类">
            <a-select
              placeholder="请选择货物种类"
              style="width: 100%"
              v-model="queryParam['good_type_id']"
            >
              <a-select-option v-for="op in goodTypeData" :key="op.id">{{ op.good_type }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="货物编码">
            <a-input v-model.trim="queryParam['good_no']" placeholder="根据货物编码搜索"/>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="货物名称">
            <a-input v-model.trim="queryParam['good_name']" placeholder="根据货物名称搜索"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin: 15px 0px;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新建</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" class="margin-right10" @click="delRecord('all')">删除</a-button>
        <a-button icon="upload" @click="download" class="margin-right10">导出</a-button>
        <a-button type="primary" @click="goodTypeVisible = true">货物种类</a-button>
        <a-button type="primary" @click="handleGoodProducer">生产厂商</a-button>
      </a-row>
    </a-form-model>

    <!-- 表格展示 -->
    <a-table
      row-key="id"
      size="small"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">编辑</a>
        <a @click="delRecord('row', record)">删除</a>
      </div>
      <div slot="priceOutInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <span style="color: #feeb34f;">金额：{{ item }}</span>
        </div>
      </div>
      <div slot="priceInInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <span style="color: #feeb34f;">金额：{{ item }}</span>
        </div>
      </div>
    </a-table>

    <!-- 新建、编辑模态框 -->
    <a-modal
      :width="650"
      v-model="visible"
      :title="modelTitle"
      @ok="handleSubmit"
      @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="货物种类">
          <a-select
            placeholder="请选择货物种类"
            style="width: 100%"
            v-model="formModel['good_type_id']"
            @change="changeGoodType"
          >
            <a-select-option v-for="op in goodTypeData" :key="op.id">{{ op.good_type }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="货物编码">
          <a-input v-model.trim="formModel.good_no" />
        </a-form-model-item>
        <a-form-model-item label="货物名称" prop="good_name">
          <a-input v-model.trim="formModel.good_name" />
        </a-form-model-item>
        <a-form-model-item label="货物单位" prop="good_unit">
          <a-input v-model.trim="formModel.good_unit" />
        </a-form-model-item>
        <a-form-model-item label="货物规格" prop="good_spec">
          <a-input-number v-model.trim="formModel.capacity" style="width: 70%" @change="changeGoodSpec"/>
          <a-select
            placeholder="请选择贮存单位"
            style="width: 30%"
            v-model="formModel['capacity_unit']"
            @change="changeGoodSpec"
            allowClear
          >
            <a-select-option v-for="op in capacityUnitTypes" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
          <a-input v-model.trim="formModel.good_spec"/>
        </a-form-model-item>
        <a-form-model-item label="产品条码">
          <a-input v-model.trim="formModel.good_barcode" allowClear/>
        </a-form-model-item>
        <a-form-model-item label="生产厂商">
          <a-select
            placeholder="请选择生产厂商"
            style="width: 100%"
            v-model="formModel['producer_id']"
            allowClear
          >
            <a-select-option v-for="op in goodProducerData" :key="op.id">{{ op.producer_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="贮存条件">
          <a-select
            placeholder="请选择贮存条件"
            style="width: 100%"
            v-model="formModel['storage_conditions']"
          >
            <a-select-option v-for="op in storageConditionTypes" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="保质期">
          <a-input-number v-model.trim="formModel.quality_period" style="width: 70%"/>
          <a-select
            placeholder="请选择贮存单位"
            style="width: 30%"
            v-model="formModel['storage_unit']"
            allowClear
          >
            <a-select-option v-for="op in storageUnitTypes" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="进项税" prop="input_tax">
          <a-input-number style="width: 100%;" v-model="formModel.input_tax" />
        </a-form-model-item>
        <a-form-model-item label="进货单价" prop="price_in">
          <a-input-group compact v-for="(item, index) in formModel.price_in" :key="index">
            <a-input-number v-model="formModel.price_in[index]" style="width: 80%" />
            <a-button type="danger" style="width: 20%" @click="delBank2(index)">删除</a-button>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 60%" @click="addBank2">
            <a-icon type="plus" /> 添加进货单价
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="销项税" prop="out_put_tax">
          <a-input-number style="width: 100%;" v-model="formModel.out_put_tax" />
        </a-form-model-item>
        <a-form-model-item label="出货单价" prop="price_out">
          <a-input-group compact v-for="(item, index) in formModel.price_out" :key="index">
            <a-input-number v-model="formModel.price_out[index]" style="width: 80%" />
            <a-button type="danger" style="width: 20%" @click="delBank(index)">删除</a-button>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 60%" @click="addBank">
            <a-icon type="plus" /> 添加出货单价
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sort">
          <a-input v-model.trim="formModel.sort" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      :width="1000"
      v-model="goodTypeVisible"
      title="货物种类"
      :footer="null"
    >
      <a-card title="种类信息" >
        <a-button slot="extra" type="primary" @click="saveType({ id: null, good_type: '', product_type: '',product_categories: '',product_classify: '' })">新增种类</a-button>
        <a-table
          row-key="id"
          size="small"
          :columns="goodTypeColumns"
          :data-source="goodTypeData"
          :pagination="false"
        >
          <div slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="saveType(record)" class="margin-right10">编辑</a>
            <a @click="removeType(record)">删除</a>
          </div>
        </a-table>
      </a-card>
    </a-modal>
    <a-modal
      :width="600"
      v-model="goodTypeModifyVisible"
      title="货物种类"
      @ok="submitGoodType"
      @cancel="goodTypeModifyVisible = false"
    >
      <a-form-model
        ref="goodTypeRuleForm"
        :model="currentGoodType"
        :rules="goodTypeRules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="货物编码">
          <a-input v-model.trim="currentGoodType.good_type" />
        </a-form-model-item>
        <a-form-model-item label="产品类型（一级）">
          <a-input v-model.trim="currentGoodType.product_type" />
        </a-form-model-item>
        <a-form-model-item label="产品大类（二级）">
          <a-input v-model.trim="currentGoodType.product_categories" />
        </a-form-model-item>
        <a-form-model-item label="产品分类（三级）">
          <a-input v-model.trim="currentGoodType.product_classify" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :width="1000"
      v-model="goodProducerVisible"
      title="生产厂商"
      :footer="null"
    >
      <a-card title="生产厂商" >
        <a-button slot="extra" type="primary" @click="saveGoodProducer({ id: null, producer_name: '', country: '中国',province: '',city: '' })">新增生产厂商</a-button>
        <a-table
          row-key="id"
          size="small"
          :columns="goodProducerColumns"
          :data-source="goodProducerData"
          :pagination="false"
        >
          <div slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="saveGoodProducer(record)" class="margin-right10">编辑</a>
            <a @click="removeGoodProducer(record)">删除</a>
          </div>
        </a-table>
      </a-card>
    </a-modal>
    <a-modal
      :width="600"
      v-model="goodProducerModifyVisible"
      title="生产厂商"
      @ok="submitGoodProducer"
      @cancel="goodProducerModifyVisible = false"
    >
      <a-form-model
        ref="goodTypeRuleForm"
        :model="currentGoodProducer"
        :rules="goodProducerRules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="生产厂商">
          <a-input v-model.trim="currentGoodProducer.producer_name" />
        </a-form-model-item>
        <a-form-model-item label="国家">
          <a-select
            placeholder="请选择国家"
            style="width: 100%"
            v-model="currentGoodProducer.country"
            @change="changeProvinceData"
            showSearch
          >
            <a-select-option v-for="op in countryData" :key="op.area_name">{{ op.area_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="省份">
          <a-select
            placeholder="请选择省份"
            style="width: 100%"
            v-model="currentGoodProducer.province"
            @change="changeCityData"
            showSearch
          >
            <a-select-option v-for="op in provinceData" :key="op.area_name">{{ op.area_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="市">
          <a-select
            placeholder="请选择市"
            style="width: 100%"
            v-model="currentGoodProducer.city"
            showSearch
          >
            <a-select-option v-for="op in cityData" :key="op.area_name">{{ op.area_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import {
  getSaleInfoPage,
  createGoodInfo,
  updateGoodInfo,
  deleteGoodInfo,
  exportGoodInfo,
  getGoodType,
  saveGoodType,
  removeGoodType,
  getGoodProducers,
  handleSaveGoodProducer,
  removeGoodProducer
} from '@/api/wms/sale';
import { downloadExcel, handleQueryAreas } from '@/api/common';
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      queryParam: {},
      formModel: {
        good_type_id: null,
        good_type: null,
        good_no: null,
        good_name: null,
        good_unit: null,
        good_spec: null,
        price_in: [0],
        input_tax: 0,
        price_out: [0],
        out_put_tax: 0,
        sort: 0,
        storage_conditions: '常温',
        quality_period: null,
        storage_unit: '天',
        capacity: null,
        capacity_unit: '毫升'
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: `货物种类`,
          dataIndex: `good_type`
        },
        // {
        //   title: `货物编码`,
        //   dataIndex: `good_no`
        // },
        {
          title: '货物名称',
          dataIndex: 'good_name'
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit'
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec'
        },
        {
          title: '产品条码',
          dataIndex: 'good_barcode'
        },
        {
          title: '生产厂商',
          dataIndex: 'producer_id',
          customRender: (text) => {
            for (const item of this.goodProducerData) {
              if (item.id === text) {
                return item.producer_name + '/' + item.province + '/' + item.city
              }
            }
          }
        },
        {
          title: '贮存条件',
          dataIndex: 'storage_conditions',
          customRender: (text, record) => {
            if (text) {
              return text + '/' + record.quality_period + '/' + record.storage_unit
            }
          }
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          scopedSlots: { customRender: 'priceInInfo' }
        },
        {
          title: '进项税',
          dataIndex: 'input_tax'
        },
        {
          title: `出货单价`,
          dataIndex: `price_out`,
          scopedSlots: { customRender: 'priceOutInfo' }
        },
        {
          title: '销项税',
          dataIndex: 'out_put_tax'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      rules: {
        good_name: [{ required: true, message: '请输入货物名称', trigger: 'blur' }],
        good_unit: [{ required: true, message: '请输入货物单位', trigger: 'blur' }],
        good_spec: [{ required: true, message: '请输入货物规格', trigger: 'blur' }],
        price_in: [{ required: true, message: '请输入进货单价', trigger: 'blur' }],
        price_out: [{ required: true, message: '请输入出货单价', trigger: 'blur' }]
      },
      goodTypeRules: {
        good_type: [{ required: true, message: '请输入货物种类', trigger: 'blur' }],
        product_type: [{ required: true, message: '请输入产品类型', trigger: 'blur' }],
        product_categories: [{ required: true, message: '请输入产品大类', trigger: 'blur' }],
        product_classify: [{ required: true, message: '请输入产品分类', trigger: 'blur' }]
      },
      visible: false,
      modelTitle: '新增货物信息',
      moduleName: 'good_info',
      flag: 'insert',
      goodTypeVisible: false,
      goodTypeColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: `货物种类`,
          dataIndex: `good_type`
        },
        {
          title: `产品类型（一级）`,
          dataIndex: `product_type`
        },
        {
          title: `产品大类（二级）`,
          dataIndex: `product_categories`
        },
        {
          title: `产品分类（三级）`,
          dataIndex: `product_classify`
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        }
      ],
      goodTypeData: [],
      goodProducerVisible: false,
      goodProducerColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: `生产厂商名称`,
          dataIndex: `producer_name`
        },
        {
          title: `国家`,
          dataIndex: `country`
        },
        {
          title: `省份`,
          dataIndex: `province`
        },
        {
          title: `市`,
          dataIndex: `city`
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' }
        }
      ],
      goodProducerData: [],
      currentGoodProducer: {
        id: null,
        producer_name: '',
        country: '',
        province: '',
        city: ''
      },
      goodProducerRules: {
        producer_name: [{ required: true, message: '请输入生产厂商名称', trigger: 'blur' }]
      },
      currentGoodType: {
        id: null,
        good_type: '',
        product_type: '',
        product_categories: '',
        product_classify: ''
      },
      goodTypeModifyVisible: false,
      goodProducerModifyVisible: false,
      storageConditionTypes: [
        { value: '常温', name: '常温' },
        { value: '冷藏', name: '冷藏' },
        { value: '冷冻', name: '冷冻' },
        { value: '长期', name: '长期' }
      ],
      storageUnitTypes: [
        { value: '天', name: '天' },
        { value: '月', name: '月' },
        { value: '年', name: '年' },
        { value: '小时', name: '小时' }
      ],
      capacityUnitTypes: [
        { value: '毫升', name: '毫升', enName: 'ML' },
        { value: '升', name: '升', enName: 'L' },
        { value: '毫克', name: '毫克', enName: 'mg' },
        { value: '克', name: '克', enName: 'g' },
        { value: '千克(公斤)', name: '千克(公斤)', enName: 'kg' },
        { value: '斤', name: '斤', enName: '斤' },
        { value: '吨', name: '吨', enName: 't' }
      ],
      countryData: [],
      provinceData: [],
      cityData: []
    }
  },
  created() {
    // 页面加载数据
    this.searchInfo();
  },
  methods: {
    // 加载初始数据
    loadData(param) {
      getSaleInfoPage(this.moduleName, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list;
        this.pagination.total = res.total;
      });
    },
    loadGoodType() {
      getGoodType().then(res => {
        this.goodTypeData = res
      })
    },
    saveType(record) {
      this.currentGoodType = record
      this.goodTypeModifyVisible = true
    },
    submitGoodType() {
      saveGoodType(this.currentGoodType).then(res => {
        if (res) {
          this.loadGoodType()
          this.goodTypeModifyVisible = false
        }
      })
    },
    removeType(record) {
      removeGoodType({
        id: record.id
      }).then(res => {
        if (res) {
          this.loadGoodType()
        }
      })
    },
    changeGoodType(value) {
      for (const item of this.goodTypeData) {
        if (item.id === value) {
          this.formModel.good_type = item.good_type
        }
      }
    },
    changeGoodSpec() {
      this.formModel.good_spec = (this.formModel.capacity ? this.formModel.capacity : '') + (this.formModel.capacity_unit ? this.formModel.capacity_unit : '')
    },
    // 查询
    searchInfo() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.loadData(Object.assign({}, this.queryParam));
      this.loadGoodType()
      this.loadGoodProducer()
    },
    // 新建
    newRecord() {
      this.modelTitle = `新增货物信息`;
      this.visible = true;
      this.flag = `insert`;
    },
    // 删除
    delRecord(key, record) {
      this.$confirm({
        title: `确认提示`,
        content: `确定要删除吗？`,
        onOk: () => {
          let _ids = [];
          if (key === 'all') {
            _ids = this.selectedRowKeys;
          } else {
            _ids = [record.id];
          }
          deleteGoodInfo(this.moduleName, { ids: _ids }).then(res => {
            this.$notification['success']({
              message: `提示`,
              description: `删除成功.`
            });
            this.searchInfo();
          });
        }
      })
    },
    download() {
      exportGoodInfo(this.moduleName, this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res };
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    },
    // 修改
    modifyRecord(record) {
      this.modelTitle = `修改货物信息`;
      this.visible = true;
      if (!record.price_in) {
        record.price_in = [0];
      }
      if (!record.price_out) {
        record.price_out = [0];
      }
      this.formModel = Object.assign({}, record);
      this.flag = `modify`;
    },
    // 表单提交
    handleSubmit() {
      let fun;
      if (this.flag === `insert`) {
        fun = createGoodInfo;
      } else if (this.flag === `modify`) {
        fun = updateGoodInfo;
      } else {
        this.$message.error('表单提交异常~');
        return;
      }
      fun(this.moduleName, this.formModel).then(res => {
        if (res) {
          this.$message.success('保存成功.');
          const param = Object.assign({}, this.queryParam);
          param.page = this.pagination.current;
          param.pageSize = this.pagination.pageSize;
          this.loadData(param);
        } else {
          this.$message.error('保存失败.');
        }
      }).finally(_ => {
        this.handleCancel();
      });
    },
    // 表单取消
    handleCancel() {
      this.visible = false;
      this.formModel = {
        good_type_id: null,
        good_type: null,
        good_no: null,
        good_name: null,
        good_unit: null,
        good_spec: null,
        price_in: [0],
        input_tax: 0,
        price_out: [0],
        out_put_tax: 0,
        sort: 0
      }
      this.$refs.ruleForm.resetFields();
    },
    // 表格分页查询
    changeTable(pagination) {
      this.pagination = pagination;
      this.loadData(Object.assign({}, this.queryParam));
    },
    // 表格选择行事件
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 添加出货单价输入栏
    addBank() {
      this.formModel.price_out.push(0);
    },
    // 添加进货单价输入栏
    addBank2() {
      this.formModel.price_in.push(0);
    },
    // 删除出货单价输入栏
    delBank(index) {
      this.formModel.price_out.splice(index, 1);
    },
    // 删除进货单价输入栏
    delBank2(index) {
      this.formModel.price_in.splice(index, 1);
    },
    handleGoodProducer() {
      this.goodProducerVisible = true
      this.loadGoodProducer()
    },
    loadGoodProducer() {
      getGoodProducers().then(res => {
        this.goodProducerData = res
      })
    },
    saveGoodProducer(record) {
      this.currentGoodProducer = record
      this.goodProducerModifyVisible = true
      this.changeCountryData()
      this.changeProvinceData()
    },
    changeCountryData() {
      handleQueryAreas({ 'parent_id': 0 }).then(res => {
        this.countryData = res
      })
    },
    changeProvinceData() {
      handleQueryAreas({ 'area_name': this.currentGoodProducer.country, 'area_type': '1' }).then(res => {
        this.provinceData = res
      })
    },
    changeCityData() {
      handleQueryAreas({ 'area_name': this.currentGoodProducer.province, 'area_type': '2' }).then(res => {
        this.cityData = res
      })
    },
    submitGoodProducer() {
      handleSaveGoodProducer(this.currentGoodProducer).then(res => {
        if (res) {
          this.loadGoodProducer()
          this.goodProducerModifyVisible = false
        }
      })
    },
    removeGoodProducer(record) {
      removeGoodProducer({
        id: record.id
      }).then(res => {
        if (res) {
          this.loadGoodProducer()
        }
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
