import { axios } from '@/utils/request'
// import { modules } from './index'

export function getPurchasePage(data) {
  return axios({
    url: `wms/sale/purchase/list_purchase`,
    method: 'post',
    data
  })
}

export function getWarehouseList() {
  return axios({
    url: `wms/data/warehouse/option`,
    method: 'get'
  })
}

export function getUserList() {
  return axios({
    url: `mms/meta/tenant_info/contact_list`,
    method: 'get'
  })
}

export function getSupplierList() {
  return axios({
    url: `common/supplier_info/option`,
    method: 'get'
  })
}

export function getFeeTypes() {
  return axios({
    url: `wms/sale/sell/wms_fee_types`,
    method: 'get'
  })
}

export function getCmsFeeTypes() {
  return axios({
    url: `wms/sale/sell/cms_fee_types`,
    method: 'get'
  })
}

// 根据付款单位获取付款对象
export function getPayTargetList(payTarget) {
  return axios({
    url: `wms/meta/${payTarget}/option`,
    method: 'get'
  })
}

// 创建进货信息
export function createPurchase(data) {
  return axios({
    url: `wms/sale/purchase/save`,
    method: 'post',
    data
  })
}

// 删除进货信息
export function deletePurchase(data) {
  return axios({
    url: `wms/sale/purchase/remove`,
    method: 'post',
    data
  })
}

export function checkPurchase(data) {
  return axios({
    url: `wms/sale/purchase/check`,
    method: 'post',
    data
  })
}

export function goodInStock(data) {
  return axios({
    url: `wms/sale/purchase/in_stock`,
    method: 'post',
    params: data
  })
}

export function goodUnInStock(data) {
  return axios({
    url: `wms/sale/purchase/un_in_stock`,
    method: 'post',
    params: data
  })
}

export function getPurchaseDetail(data) {
  return axios({
    url: `wms/sale/purchase/purchase_detail`,
    method: 'get',
    params: data
  })
}

// 出库******

export function getSellPage(parameter) {
  return axios({
    url: `wms/sale/sell/sell_list`,
    method: 'post',
    data: parameter
  })
}

export function sellAllTotal(data) {
  return axios({
    url: 'wms/sale/sell/sell_all_total',
    method: 'post',
    data
  })
}

// 创建出货信息
export function createSell(data) {
  return axios({
    url: `wms/sale/sell/save`,
    method: 'post',
    data
  })
}

// 进销存-出货-退货
export function cancelSell(data) {
  return axios({
    url: `wms/sale/sell/cancelOrder`,
    method: 'post',
    data
  })
}

// 进销存-出货-取消退货
export function unCancelSell(data) {
  return axios({
    url: 'wms/sale/sell/unCancelOrder',
    method: 'post',
    data
  })
}

export function getSellDetail(data) {
  return axios({
    url: `wms/sale/sell/sell_detail`,
    method: 'get',
    params: data
  })
}

export function getPurchaseList() {
  return axios({
    url: `wms/sale/sell/get_purchase_list`,
    method: 'get'
  })
}

// 根据进货单id获取商品信息
export function getPurchaseGoods(id) {
  return axios({
    url: `wms/sale/sell/list_goods_by_purchase?id=${id}`,
    method: 'get'
  })
}

// 根据进货单商品名称获取商品信息
export function getPurchaseGoodsByName(name) {
  return axios({
    url: `wms/sale/sell/list_goods_by_name?name=${name}`,
    method: 'get'
  })
}

// 获取出货货物信息
export function getSellGoodsList(id) {
  return axios({
    url: `wms/sale/sell/list_goods_by_sell?id=${id}`,
    method: 'get'
  })
}

// 获取出货费用信息
export function getSellFeeList(id) {
  return axios({
    url: `wms/sale/sell/list_out_fees_by_id?id=${id}`,
    method: 'get'
  })
}

// 库存******
export function getStockPage(data) {
  return axios({
    url: `wms/sale/stock/show_list`,
    method: 'post',
    data
  })
}

export function getStockSummaryPage(data) {
  return axios({
    url: `wms/sale/stock/show_summary_list`,
    method: `post`,
    data
  })
}

export function exportStockDetail(data) {
  return axios({
    url: `wms/sale/stock/export`,
    method: 'post',
    data
  })
}

export function exportInAndOut(data) {
  return axios({
    url: `wms/sale/stock/exportInAndOut`,
    method: `post`,
    data
  })
}

export function exportCalendarYearInAndOutSheet(data) {
  return axios({
    url: `wms/sale/stock/exportCalendarYearInAndOutSheet`,
    method: `post`,
    data
  })
}

export function exportStockByDate(data) {
  return axios({
    url: `wms/sale/stock/exportByDate`,
    method: `post`,
    data
  })
}

export function exportInsideConsumptionSheet(params) {
  return axios({
    url: `wms/sale/stock/exportInsideConsumptionSheet`,
    method: `get`,
    params
  })
}

export function exportGoodStockSheet(params) {
  return axios({
    url: `wms/sale/stock/exportGoodStockSheet`,
    method: `get`,
    params
  })
}

// 统计分析****

export function getStaticsShowInDetail(data) {
  return axios({
    url: `wms/sale/statics/show_in_detail`,
    method: 'post',
    data
  })
}

export function getStaticsShowOutDetail(data) {
  return axios({
    url: `wms/sale/statics/show_out_detail`,
    method: 'post',
    data
  })
}

export function getStaticsShowAgentDetail(data) {
  return axios({
    url: `wms/sale/statics/show_agent_detail`,
    method: 'post',
    data
  })
}

export function getFmsStaticsShowInDetail(parameter) {
  return axios({
    url: `wms/sale/statics/fms_show_in_detail`,
    method: 'get',
    params: parameter
  })
}

export function getFmsStaticsShowOutDetail(parameter) {
  return axios({
    url: `wms/sale/statics/fms_show_out_detail`,
    method: 'get',
    params: parameter
  })
}

export function getFmsStaticsShowAgentDetail(parameter) {
  return axios({
    url: `wms/sale/statics/fms_show_agent_detail`,
    method: 'get',
    params: parameter
  })
}

// export function exportStaticsDetail(parameter) {
//   return axios({
//     url: `wms/sale/statics/export_data`,
//     method: 'get',
//     params: parameter
//   })
// }

// export function exportInvoiceStaticsDetail(parameter) {
//   return axios({
//     url: `wms/sale/statics/export_invoice_data`,
//     method: 'get',
//     params: parameter
//   })
// }

export function getSaleInfoPage(type, data) {
  return axios({
    url: `wms/sale/${type}/getGoodInfoPage`,
    method: 'post',
    data
  })
}

export function createSaleInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/create`,
    method: 'post',
    data
  })
}

export function deleteSaleInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/delete`,
    method: 'post',
    data
  })
}

export function updateSaleInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/update`,
    method: 'post',
    data
  })
}

export function getSaleOptions(type, params) {
  return axios({
    url: `wms/sale/${type}/option`,
    method: 'get',
    params
  })
}

// 代销页面查询 API
export function getAgentPage(parameter) {
  return axios({
    url: `wms/sale/agent/agent_list`,
    method: 'post',
    data: parameter
  })
}

export function agentAllTotal(data) {
  return axios({
    url: `wms/sale/agent/agent_all_total`,
    method: 'post',
    data
  })
}

// 获取代销货物信息 API
export function getAgentGoodsList(id) {
  return axios({
    url: `wms/sale/agent/agent_detail?id=${id}`,
    method: 'get'
  })
}

// 获取代销费用信息 API
export function getAgentFeeList(id) {
  return axios({
    url: `wms/sale/agent/list_agent_fees_by_id?id=${id}`,
    method: 'get'
  })
}

// 创建代销订单信息 API
export function createAgent(data) {
  return axios({
    url: `wms/sale/agent/save`,
    method: 'post',
    data
  })
}

// 删除代销订单信息
export function deleteAgentByID(data) {
  return axios({
    url: `wms/sale/agent/remove`,
    method: 'post',
    data
  })
}

export function createGoodInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/create`,
    method: 'post',
    data
  })
}

export function updateGoodInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/update`,
    method: 'post',
    data
  })
}

export function deleteGoodInfo(type, data) {
  return axios({
    url: `wms/sale/${type}/delete`,
    method: 'post',
    data
  })
}

export function exportGoodInfo(type, params) {
  return axios({
    url: `wms/sale/${type}/exportGoodInfoList`,
    method: `get`,
    params
  })
}

export function getGoodInfoList() {
  return axios({
    url: `wms/sale/good_info/goodInfoList`,
    method: 'get'
  })
}

export function getGoodInfo(params) {
  return axios({
    url: `wms/sale/good_info/getGoodInfo`,
    method: 'get',
    params
  })
}

export function getGoodStockInfo() {
  return axios({
    url: `wms/sale/stock/get_stock_info`,
    method: 'get'
  })
}

export function getStockInDetailInfo(data) {
  return axios({
    url: `wms/sale/stock/getStockInDetailInfo`,
    method: `post`,
    data
  })
}

export function getStockOutDetailInfo(data) {
  return axios({
    url: `wms/sale/stock/getStockOutDetailInfo`,
    method: `post`,
    data
  })
}

export function getPurchaseGoodList() {
  return axios({
    url: `wms/sale/purchase/purchase_good_list`,
    method: `get`
  })
}

export function addGoodStock(data) {
  return axios({
    url: `wms/sale/stock/add_stock`,
    method: 'post',
    data
  })
}

export function modifyStockArrive(data) {
  return axios({
    url: `wms/sale/stock/modify_arrive`,
    method: 'post',
    data
  })
}

export function getWmsBalanceFunc(type, parameter) {
  return axios({
    url: `wms/sale/balance/${type}`,
    method: 'post',
    data: parameter
  })
}

export function getWmsBalanceByIdFuc(type, params) {
  return axios({
    url: `wms/sale/balance/${type}`,
    method: 'get',
    params: params
  })
}

export function updateWmsBalance(type, params) {
  return axios({
    url: `/wms/sale/balance/${type}`,
    method: 'put',
    data: params
  })
}

export function delWmsBalance(type, params) {
  return axios({
    url: `/wms/sale/balance/${type}`,
    method: 'delete',
    data: params
  })
}

export function getWmsPaymentFunc(type, parameter) {
  return axios({
    url: `wms/payment/${type}`,
    method: 'post',
    data: parameter
  })
}

export function getWmsPaymentByIdFuc(type, params) {
  return axios({
    url: `wms/payment/${type}`,
    method: 'get',
    params: params
  })
}

export function updateWmsPayment(type, params) {
  return axios({
    url: `/wms/payment/${type}`,
    method: 'put',
    data: params
  })
}

export function delWmsPayment(type, params) {
  return axios({
    url: `/wms/payment/${type}`,
    method: 'delete',
    data: params
  })
}

export function getWmsGoodStockIdByGoodId(params) {
  return axios({
    url: `/wms/sale/stock/getWmsGoodStockIdByGoodId`,
    method: `get`,
    params
  })
}

export function getSaleReceivingAddressPage(type, params) {
  return axios({
    url: `wms/sale/${type}/list`,
    method: `get`,
    params
  })
}

export function getAllReceivingAddress(params) {
  return axios({
    url: `wms/sale/receiving_address/getAll`,
    method: `get`,
    params
  })
}

export function getAllEscortMode(params) {
  return axios({
    url: `wms/sale/escort_mode/getAll`,
    method: `get`,
    params
  })
}

export function deleteReceivingAddress(type, data) {
  return axios({
    url: `wms/sale/${type}/delete`,
    method: `post`,
    data
  })
}

export function createReceivingAddress(type, data) {
  return axios({
    url: `wms/sale/${type}/create`,
    method: `post`,
    data
  })
}

export function updateReceivingAddress(type, data) {
  return axios({
    url: `wms/sale/${type}/update`,
    method: `post`,
    data
  })
}

// 查询打印销售入库单的数据
export function getSalePurchaseSheet(params) {
  return axios({
    url: `wms/sale/purchase/getSalePurchaseSheet`,
    method: `get`,
    params
  })
}

// 查询打印销售出库单的数据
export function getSaleSellSheet(params) {
  return axios({
    url: `wms/sale/sell/getSaleSellSheet`,
    method: `get`,
    params
  })
}

// 查询打印销售代销单的数据
export function getSaleAgentSheet(params) {
  return axios({
    url: `wms/sale/agent/getSaleAgentSheet`,
    method: `get`,
    params
  })
}

// 根据供应商id查询入库单信息
export function getPurchaseNumList(params) {
  return axios({
    url: `wms/sale/purchase/getPurchaseNumList`,
    method: `get`,
    params
  })
}

// 根据入库单id查询入库库存货物信息
export function getGoodStockByPurchaseId(params) {
  return axios({
    url: `wms/sale/stock/getGoodStockByPurchaseId`,
    method: `get`,
    params
  })
}

// 导出代销月度统计表
export function exportMonthlyStatisticsData(data) {
  return axios({
    url: `wms/sale/agent/exportMonthlyStatisticsData`,
    method: 'post',
    data
  })
}

// 导出代销月度详情表
export function exportAgentInfoExport(data) {
  return axios({
    url: `wms/sale/agent/exportAgentInfoExport`,
    method: `post`,
    data
  })
}

export function getGoodType() {
  return axios({
    url: `wms/sale/good_info/get_good_type`,
    method: 'get'
  })
}

export function saveGoodType(data) {
  return axios({
    url: `wms/sale/good_info/save_good_type`,
    method: 'post',
    data
  })
}

export function removeGoodType(data) {
  return axios({
    url: `wms/sale/good_info/remove_good_type`,
    method: 'post',
    params: data
  })
}

export function getGoodProducers() {
  return axios({
    url: `wms/sale/good_info/get_good_producer`,
    method: 'get'
  })
}

export function handleSaveGoodProducer(data) {
  return axios({
    url: `wms/sale/good_info/save_good_producer`,
    method: 'post',
    data
  })
}

export function removeGoodProducer(data) {
  return axios({
    url: `wms/sale/good_info/remove_good_producer`,
    method: 'post',
    params: data
  })
}
// 进销存~库存盘点单（汇总表）
export function handleSumExport(data) {
  return axios({
    url: `wms/sale/stock/handleSumExport`,
    method: `post`,
    data
  })
}

// 进销存~已开票盘点单（汇总表）
export function handleExportInAndOutSum(data) {
  return axios({
    url: `wms/sale/stock/handleExportInAndOutSum`,
    method: `post`,
    data
  })
}

// 进销存~未开票盘点单（汇总表）
export function handleExportCalendarYearInAndOutSum(data) {
  return axios({
    url: `wms/sale/stock/handleExportCalendarYearInAndOutSum`,
    method: `post`,
    data
  })
}

// 导出指定年份的销售清单（出库）
export function exportSellSalesSheet(data) {
  return axios({
    url: `wms/sale/sell/exportSellSalesSheet`,
    method: `post`,
    data
  })
}

// 导出指定年份的销售清单（代销）
export function exportAgentSalesSheet(data) {
  return axios({
    url: `wms/sale/agent/exportAgentSalesSheet`,
    method: `post`,
    data
  })
}

// 导出指定年份的销售清单（出库、代销）全部
export function exportAllSalesSheet(data) {
  return axios({
    url: `wms/sale/sell/exportAllSalesSheet`,
    method: `post`,
    data
  })
}

// 导出商品销量总数总额（出库、代销）全部
export function exportStatisticsSaleGoodsTotalSheet(data) {
  return axios({
    url: `wms/sale/sell/exportStatisticsSaleGoodsTotal`,
    method: `post`,
    data
  })
}

// 导出指定年份（出货、代销）不同订单类型的销售统计表
export function exportAllSalesStatisticsSheet(data) {
  return axios({
    url: `wms/sale/sell/exportAllSalesStatisticsSheet`,
    method: 'post',
    data
  })
}

// 进销存~利润明细表
export function exportProfitSheet(data) {
  return axios({
    url: `wms/sale/stock/exportProfitSheet`,
    method: `post`,
    data
  })
}

export function exportOrderProfitSheet(data) {
  return axios({
    url: `wms/sale/finance/profit_export`,
    method: `post`,
    data
  })
}

// 进销存~出入库总表导出
export function handleInAndOutSumSheetExport(data) {
  return axios({
    url: `wms/sale/stock/handleInAndOutSumSheetExport`,
    method: `post`,
    data
  })
}

// 进销存~库存盘点表（汇总表）
export function exportInventoryCountSumSheet(data) {
  return axios({
    url: `wms/sale/stock/exportInventoryCountSumSheet`,
    method: `post`,
    data
  })
}

// 进销存~按年汇总统计出入库数量（财务账面）
export function exportFinnaceInOutSheet(data) {
  return axios({
    url: `wms/sale/stock/exportFinnaceInOut`,
    method: `post`,
    data
  })
}

// 营销分析表导出
export function exportMarketingAnalysisSheet(data) {
  return axios({
    url: `wms/sale/statics/exportMarketingAnalysisSheet`,
    method: 'post',
    data
  })
}

// 供应商销售统计表导出
export function exportSupplierAnalysisSheet(data) {
  return axios({
    url: `wms/sale/sell/exportStatisticsSheetBySupplier`,
    method: 'post',
    data
  })
}
// 查询货物信息
export function getGoodDetailBySellId(params) {
  return axios({
    url: `wms/sale/sell/good_detail_by_sellId`,
    method: 'get',
    params
  })
}

// 审核发货状态
export function handleGoodOutShipment(data) {
  return axios({
    url: `wms/sale/sell/auditSendNum`,
    method: `post`,
    data
  })
}

// 审核发货状态
export function auditShipment(type, data) {
  return axios({
    url: `wms/sale/sell/${type}`,
    method: `post`,
    data
  })
}

// 导出物流清单表
export function exportLogisticsSheet(data) {
  return axios({
    url: `wms/sale/sell/exportLogisticsSheet`,
    method: 'post',
    data
  })
}

export function handleProductTraceability(data) {
  return axios({
    url: `wms/sale/sell/productTraceability`,
    method: 'post',
    data
  })
}

export function handleExportIn(data) {
  return axios({
    url: `wms/sale/stock/handleInSheetExport`,
    method: `post`,
    data
  })
}

export function handleExportOut(data) {
  return axios({
    url: `wms/sale/stock/handleOutSheetExport`,
    method: `post`,
    data
  })
}

export function handleExportAgent(data) {
  return axios({
    url: `wms/sale/stock/handleAgentSheetExport`,
    method: `post`,
    data
  })
}

export function importCourierInfo(data) {
  return axios({
    url: `wms/sale/sell/importCourierInfo`,
    method: 'post',
    data
  })
}

// 进销存-出货订单-退款申请
export function sellRefundApply(data) {
  return axios({
    url: 'wms/sale/sell/refund_apply',
    method: 'post',
    data
  })
}

// 进销存-代销订单-退款申请
export function agentRefundApply(data) {
  return axios({
    url: 'wms/sale/agent/refund_apply',
    method: 'post',
    data
  })
}

// 进销存-退款申请-查询订单中已交易的商品信息
export function listPayDealGoodInfo(data) {
  return axios({
    url: '/wms/finance/refund_apply/listPayDealGoodInfo',
    method: 'post',
    data
  })
}

// 进销存-退款申请-分页
export function pageRefundApply(data) {
  return axios({
    url: '/wms/finance/refund_apply/list',
    method: 'post',
    data
  })
}

// 进销存-退款申请-提交财商审核
export function applyRefundAuditCheck(data) {
  return axios({
    url: '/wms/finance/refund_apply/applyRefundAuditCheck',
    method: 'post',
    data
  })
}

// 进销存-撤销退款申请
export function cancelRefundApply(data) {
  return axios({
    url: '/wms/finance/refund_apply/cancelRefundApply',
    method: 'post',
    data
  })
}

// 财商-退款申请-分页查询
export function pageAuditRefundApply(data) {
  return axios({
    url: '/wms/finance/refund_apply/auditRefundApplyPage',
    method: 'post',
    data
  })
}

// 财商-同意退款申请-通联退款
export function approveRefund(data) {
  return axios({
    url: '/wms/finance/refund_apply/approveRefund',
    method: 'post',
    data
  })
}

// 财商-驳回退款申请
export function rejectRefundApply(data) {
  return axios({
    url: '/wms/finance/refund_apply/rejectRefundApply',
    method: 'post',
    data
  })
}

// 进销存-出货页面初始化数据
export function initSellData() {
  return axios({
    url: `/wms/sale/sell/init`,
    method: 'get'
  })
}

// 进销存-出货订单地址分配送货数量
export function distrAddressGood(data) {
  return axios({
    url: `/wms/sale/sell/distrAddressGood`,
    method: 'post',
    data
  })
}

// 进销存-出货订单反审核取消分配送货数量
export function unDistrAddressGood(data) {
  return axios({
    url: `/wms/sale/sell/unDistrAddressGood`,
    method: 'post',
    data
  })
}

// 统计出货业务快递数、快递包裹数、送货上门票数
export function staticsSellCourier(data) {
  return axios({
    url: `wms/sale/statics/statics_sell_courier`,
    method: 'post',
    data
  })
}

// 导出统计的出货业务快递数、快递包裹数、送货上门票数
export function exportStaticsSellCourier(data) {
  return axios({
    url: `wms/sale/statics/export_statics_sell_courier`,
    method: 'post',
    data
  })
}

// 查询打印销售出库单
export function getPrintSellOrder(sellId) {
  return axios({
    url: `wms/sale/sell/getPrintSellOrder/${sellId}`,
    method: 'get'
  })
}

export function statisticByCustomer(data) {
  return axios({
    url: `wms/sale/statics/statisticByCustomer`,
    method: 'post',
    data
  })
}

export function statisticBygoods(data) {
  return axios({
    url: `wms/sale/statics/statisticByGoods`,
    method: 'post',
    data
  })
}

export function statisticMonths(data) {
  return axios({
    url: `wms/sale/statics/statisticMonths`,
    method: 'post',
    data
  })
}

export function initSaleAdjust() {
  return axios({
    url: `wms/sale/adjust-order/init`,
    method: 'get'
  })
}

export function pageListSaleAdjust(data) {
  return axios({
    url: `wms/sale/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function saveSaleAdjustOrder(data) {
  return axios({
    url: `wms/sale/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function getSellDetailBySellNo(data) {
  return axios({
    url: `wms/sale/sell/getSellDetailBySellNo`,
    method: 'get',
    params: data
  })
}

export function getAgentDetailByAgentNo(data) {
  return axios({
    url: `wms/sale/agent/getAgentDetailByAgentNo`,
    method: 'get',
    params: data
  })
}

export function getPurchaseDetailByPruchaseNo(data) {
  return axios({
    url: `wms/sale/purchase/getPurchaseDetailByPruchaseNo`,
    method: 'get',
    params: data
  })
}

export function submitSaleApplyAdjust(data) {
  return axios({
    url: `wms/sale/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteSaleAdjustOrder(data) {
  return axios({
    url: `wms/sale/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}

export function getBothOuterStatics(data) {
  return axios({
    url: `wms/sale/statics/queryBothOuterStatics`,
    method: 'post',
    data
  })
}

export function handleExportBothOuterDetail(data) {
  return axios({
    url: `wms/sale/statics/exportBothOuterDetail`,
    method: 'post',
    data
  })
}

export function queryPaymentTax(data) {
  return axios({
    url: `wms/payment/statistic_tax`,
    method: 'post',
    data
  })
}

// 生成每月库存盘点
export function saveStockStatistic(data) {
  return axios({
    url: `wms/sale/stock/saveStockStatistic`,
    method: `post`,
    data
  })
}

export function queryStockStatisticPage(data) {
  return axios({
    url: `wms/sale/statistic/getStatisticPage`,
    method: `post`,
    data
  })
}

export function exportStockStatistic(data) {
  return axios({
    url: `wms/sale/statistic/exportStatistic`,
    method: `post`,
    data
  })
}

export function checkStockStatistic(data) {
  return axios({
    url: `wms/sale/statistic/checkStock`,
    method: `post`,
    data
  })
}
